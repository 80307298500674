import React from 'react'
import queryString from 'query-string'
import NoSuchEvent from './NoSuchEvent'
import NoActiveMoment from './NoActiveMoment'
import NoWebViewSupport from './NoWebViewSupport'
import AuthProblem from './AuthProblem'
import {isStaging} from './utils'

export default class Lobby extends React.Component {
  constructor() {
    super()
    this.state = {
      reason: 'no_reason',
    }
  }

  componentDidMount() {
    this.queryParams = queryString.parse(this.props.location.search)
    this.setState({reason: this.queryParams.reason})
  }

  lookupComponent(reason) {
    switch(reason) {
    case 'no_such_moment':
      return <NoActiveMoment code={this.queryParams.code} authToken={this.queryParams.authToken} isStaging={isStaging()}/>
    case 'no_such_event':
      return <NoSuchEvent code={this.queryParams.code} authToken={this.queryParams.authToken} isStaging={isStaging()}/>
    case 'no_result_page':
      return <NoWebViewSupport code={this.queryParams.code} authToken={this.queryParams.authToken} isStaging={isStaging()}/>
    case 'no_such_user':
      return <AuthProblem code={this.queryParams.code} authToken={this.queryParams.authToken} isStaging={isStaging()}/>
    default:
      return null
    }
  }

  render() {
    let c = this.lookupComponent(this.state.reason)
    return c
  }
}
