import React from 'react'

export default class Redirector extends React.Component {
  componentDidMount() {
    window.location.replace(`https://console.staging.stagecast.io/api/open${window.location.search}`)
  }

  render() {
    return (<div>redirecting...</div>)
  }
}
