import React from 'react'
import './css/noActiveMoment.css'
import {redirectToCode, isStaging} from './utils'


export default class NoActiveMoment extends React.Component {
  constructor() {
    super()
    this.handleMessage = this.handleMessage.bind(this)
  }

  componentDidMount() {
    // connect to websocket
    this.getEventId(this.props.code)
      .then(eventId => {
        this.setState({ event: eventId })
        let host = this.getHost()
        let url = `wss://${host}/api/events/${eventId}/ws`
        this.ws = new WebSocket(url)
        this.ws.onopen = () => { console.log(`connected to ${url}`) }
        this.ws.onmessage = this.handleMessage
      })
  }

  handleMessage(event) {
    console.log(`got message: ${event.data}`)
    let msg = JSON.parse(event.data)
    if (msg.type === 'start') {
      redirectToCode(this.state.event, this.props.authToken, isStaging())
    }
  }

  componentWillUnmount() {
    // close websocket
    this.ws.close()
    this.ws = null
  }

  render() {
    return (
    <div class="container">
      <div class="card pulse-animation">
        <div class="card-header-img">
          <img src={require('./images/party-popper.png')} alt="Party" />
        </div>
        <h1 class="card-title">Get Ready!</h1>
        <h2 class="card-subtitle">When a Moment is launched its results will appear here.</h2>
      </div>
    </div>)
  }

  getHost() {
    return isStaging() ? 'console.staging.stagecast.io' : 'console.stagecast.io'
  }

  getEventId(code) {
    let host = this.getHost()
    return fetch(`https://${host}/api/events/codes/${code}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response
        }
        return response.json()
      })
      .then((responseBodyJson) => {
        return responseBodyJson._id
      })
  }
}
