import React from 'react'
import './css/join.css'
import queryString from 'query-string'
import {isStaging, redirectToCode, redirectToActivation} from './utils'

export default class Join extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    this.queryParams = queryString.parse(this.props.location.search)
    if (this.queryParams.code) {
      this.setState({code: this.queryParams.code})
      this.doIt()
    }

    if (this.queryParams.activation) {
      this.setState({activation: this.queryParams.activation})
      this.doIt()
    }
  }

  doIt() {
    if (this.queryParams.code && this.queryParams.auth_token) {
      let code = this.queryParams.code
      redirectToCode(code, this.queryParams.auth_token, isStaging())
    }

    if (this.queryParams.activation && this.queryParams.auth_token) {
      let activation = this.queryParams.activation
      redirectToActivation(activation, this.queryParams.auth_token, isStaging())
    }
  }

  render() {
    return null
  }
}
