
function makeid(length) {
   var result           = ''
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
   var charactersLength = characters.length
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
   }
   return result
}

function redirectActivation(activation, uid, isStaging) {
  let noise = makeid(15)
  let params = window.location.search.length === 0 ?
      `?activationId=${activation}&deviceId=${uid}&type=results&t=${noise}` :
      `${window.location.search}&activationId=${activation}&deviceId=${uid}&type=results&t=${noise}`

  window.location.replace(`/api/open${params}`)
}

function redirect(code, uid, isStaging) {
  let noise = makeid(15)
  let params = window.location.search.length === 0 ?
      `?deviceId=${uid}&type=results&t=${noise}` :
      `${window.location.search}&deviceId=${uid}&type=results&t=${noise}`
  window.location.replace(`/api/open${params}`)
}

function isStaging() {
    return window.location.hostname.includes('staging')
}

function redirectToCode(code, authToken, isStaging) {
  let token = parseJwt(authToken)
  let uid = token.sub
  redirect(code, uid, isStaging)
}

function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

function redirectToActivation(activation, authToken, isStaging) {
  let token = parseJwt(authToken)
  let uid = token.sub
  redirectActivation(activation, uid, isStaging)
}


export {
  isStaging,
  redirectToActivation,
  redirectToCode,
}
