
import React from 'react'
import './css/noWebViewMoment.css'
import {redirectToCode} from './utils'


export default class NoWebViewSupport extends React.Component {
  constructor() {
    super()
    this.handleMessage = this.handleMessage.bind(this)
  }

  componentDidMount() {
    // connect to websocket
    this.getEventId(this.props.code)
      .then(eventId => {
        this.setState({ event: eventId })
        let host = this.getHost()
        let url = `wss://${host}/api/events/${eventId}/ws`
        this.ws = new WebSocket(url)
        this.ws.onopen = () => { console.log(`connected to ${url}`) }
        this.ws.onmessage = this.handleMessage
      })
  }

  handleMessage(event) {
    console.log(`got message: ${event.data}`)
    let msg = JSON.parse(event.data)
    if (msg.type === 'start') {
      redirectToCode(this.state.event, this.props.authToken, this.isStaging())
    }
  }

  componentWillUnmount() {
    // close websocket
    this.ws.close()
    this.ws = null
  }

  isStaging() { return this.props.isStaging === 'true' }

  render() {
    return (
      <div id="container">
        <div id="msgBox">
          <div id="circle">
            <img id="thinking" src={require('./images/thinking.png')} alt="hmm"/>
          </div>
          The current active Moment does<br/>
          not offer a result page.
        </div>
      </div>)
  }

  getHost() {
    return this.props.isStaging === 'true' ? 'console.staging.stagecast.io' : 'console.stagecast.io'
  }

  getEventId(code) {
    let host = this.getHost()
    let options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      }
    }
    return fetch(`https://${host}/api/events/codes/${code}`, options)
      .then((response) => {
        if (!response.ok) {
          throw response
        }
        return response.json()
      })
      .then((responseBodyJson) => {
        return responseBodyJson._id
      })
  }
}
